import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BedFinder from "../components/BedFinder/BedFinder"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const MattressFinderPage = ({ location }) => {
  const {
    localWpGraphQlPages: {
      pageId: post_id,
      seo: seo,
      heading: { subTitle },
      featuredImage: {
        imageFile: {
          localFile: {
            childImageSharp: { fluid },
          },
        },
      },
    },
  } = useStaticQuery(MATTRESS_FINDER_PAGE_META)

  return (
    <BedFinder
      location={location}
      defaultProductType={"mattresses"}
      post_id={post_id}
      seo={seo}
      subTitle={subTitle}
      fluid={fluid}
    />
  )
}

export default MattressFinderPage

// ==================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ==================

const MATTRESS_FINDER_PAGE_META = graphql`
  {
    localWpGraphQlPages(slug: { eq: "mattress-finder" }) {
      pageId
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      featuredImage {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      heading {
        subTitle
      }
    }
  }
`
